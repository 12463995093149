import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import SVGInline from 'react-svg-inline'
import noop from 'lodash/noop'

import './card-footer.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import Linka from 'client/shared/blocks/linka'

import { ShareButton, ShareAnalytics } from 'client/shared/blocks/share-button'
import { ContextMenuButton } from 'client/shared/blocks/context-menu-button'

import LikeIcon from 'client/shared/icons/like.svg'
import LikeActiveIcon from 'client/shared/icons/like-active.svg'
import AddToShelfIcon from 'client/shared/icons/shelf-add.svg'
import CommentIcon from 'client/shared/icons/comment.svg'

import { ShareData } from 'client/shared/types/share'
import { CardKind } from 'client/bookmate/blocks/card'
import { ContextMenuDataProps } from 'client/shared/blocks/context-menu'
import { isShareAvailable } from 'client/shared/helpers/socials-helpers'

type Props = DecoratorProps & {
  likeCount?: number
  addToShelfLink?: string
  commentCount?: number
  shareCount?: number
  shareData?: ShareData
  analytics?: ShareAnalytics
  menuData?: ContextMenuDataProps
  onLikeClick: () => void
  onCommentClick: () => void
  liked: boolean
  country: string
  kind: CardKind
}

type State = {
  liked: boolean
}

class _CardFooter extends Component<Props, State> {
  static defaultProps = {
    onCommentClick: noop,
    onLikeClick: noop,
  }

  onLikeClick = (): void => {
    const { onLikeClick } = this.props

    if (onLikeClick) {
      onLikeClick()
    }
  }

  render(): JSX.Element {
    const {
      kind,
      likeCount,
      addToShelfLink,
      commentCount,
      shareCount,
      shareData,
      onCommentClick,
      team,
      menuData,
      liked,
      country,
    } = this.props
    const likeMod = liked ? 'card-footer__action_like-active' : ''

    return (
      <div className="card-footer">
        <ul className="card-footer__counters">
          {likeCount ? (
            <li className="card-footer__counter">
              <Trans id="card.like_count" values={{ count: likeCount }} />
            </li>
          ) : null}
          {commentCount && onCommentClick ? (
            <li
              className="card-footer__counter card-footer__counter_link"
              onClick={onCommentClick}
            >
              <Trans id="card.comment_count" values={{ count: commentCount }} />
            </li>
          ) : null}
          {shareCount ? (
            <li className="card-footer__counter">
              <Trans id="card.share_count" values={{ count: shareCount }} />
            </li>
          ) : null}
        </ul>
        <ul className="card-footer__actions">
          <li
            className={`card-footer__action card-footer__action_like ${likeMod}`}
            onClick={this.onLikeClick}
          >
            <SVGInline
              className="card-footer__icon"
              svg={likeMod ? LikeActiveIcon : LikeIcon}
            />
            <span className="card-footer__icon-title">
              <Trans id="card.like" />
            </span>
          </li>
          {onCommentClick && (
            <li className="card-footer__action" onClick={onCommentClick}>
              <SVGInline className="card-footer__icon" svg={CommentIcon} />
              <span className="card-footer__icon-title">
                <Trans id={kind === 'thread' ? 'card.reply' : 'card.comment'} />
              </span>
            </li>
          )}
          {shareData && isShareAvailable(country) && (
            <li className="card-footer__action">
              <ShareButton
                shareData={shareData}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                analytics={{ ...this.props.analytics }}
              />
            </li>
          )}
          {team && addToShelfLink && (
            <Linka className="card-footer__link" path={addToShelfLink}>
              <li className="card-footer__action">
                <SVGInline className="card-footer__icon" svg={AddToShelfIcon} />
                <span className="card-footer__icon-title">
                  <Trans id="menu.add_to_shelf" />
                </span>
              </li>
            </Linka>
          )}
          {menuData && Boolean(menuData.items.length) && (
            <li className="card-footer__action card-footer__action_more">
              <ContextMenuButton menuData={menuData} />
            </li>
          )}
        </ul>
      </div>
    )
  }
}

export const CardFooter = uiBox(_CardFooter)
