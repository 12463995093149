import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import cn from 'classnames'

import './card-header.styl'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import Linka from 'client/shared/blocks/linka'
import { UserAvatar } from 'client/shared/blocks/user-avatar'
import RelativeDate from 'client/shared/blocks/relative-date'
import NoIndex from 'client/shared/blocks/noindex'

import { AuthorProps } from 'client/shared/types/author'
import { CreatorProps } from 'client/shared/types/creator'
import { CardKind } from 'client/bookmate/blocks/card'

type Props = {
  user: CreatorProps | AuthorProps
  userPath?: string
  actionMessage: React.ReactNode | string
  actionValues: { gender?: string }
  path: string
  item?: {
    title: string
    url: string
  }
  date: number
  kind: CardKind
  isLoggedIn?: boolean
  showAuthPopup?: () => void
  query?: QueryParams
}

export class CardHeader extends Component<Props> {
  static defaultProps = {
    actionMessage: '',
    actionValues: {},
    kind: 'default',
  }

  getUserPath(): string {
    const { userPath, user, query } = this.props

    if (userPath) {
      return userPath
    } else if (typeof user.login === 'string') {
      return urlFor.user(user.login, query)
    } else {
      return '#'
    }
  }

  getUserName(): string {
    const { user } = this.props

    return user.name || (typeof user.login === 'string' ? user.login : '')
  }

  render(): JSX.Element {
    const { actionMessage, actionValues, item, kind } = this.props

    const action =
      actionMessage && typeof actionMessage === 'string' ? (
        <Trans id={actionMessage} values={actionValues} />
      ) : (
        actionMessage
      )

    return (
      <div className={`card-header card-header_${kind}`}>
        {this.renderAvatar()}
        <span className="card-header__wrapper">
          {this.renderName()}
          {action && <span className="card-header__action">{action}</span>}
          {item && (
            <Linka path={item.url} className="card-header__item">
              {item.title}
            </Linka>
          )}
          {this.renderDate()}
        </span>
      </div>
    )
  }

  renderAvatar(): JSX.Element {
    const { user, kind } = this.props

    return (
      <Linka
        path={this.getUserPath()}
        className="card-header__useravatar"
        loginWall
        pseudo
      >
        <UserAvatar
          user={user}
          size={['nested', 'thread'].includes(kind) ? 24 : 40}
        />
      </Linka>
    )
  }

  renderName(): JSX.Element {
    return (
      <Linka
        path={this.getUserPath()}
        className="card-header__username"
        loginWall
        pseudo
      >
        <NoIndex>{this.getUserName()}</NoIndex>
      </Linka>
    )
  }

  renderDate(): JSX.Element {
    const { date, path, isLoggedIn, showAuthPopup } = this.props

    if (path) {
      return (
        <Linka path={path} className="card-header__date" loginWall pseudo>
          <RelativeDate value={date} />
        </Linka>
      )
    }

    return (
      <span
        className={cn(
          'card-header__date',
          !isLoggedIn && 'card-header__date_pseudolink',
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={isLoggedIn ? null : showAuthPopup}
      >
        <RelativeDate value={date} />
      </span>
    )
  }
}
