import React, { Component, createElement } from 'react'
import './card.styl'

import { connect } from 'react-redux'
import { CardHeader } from './card-header'
import { CardExtraHeader } from './card-extra-header'
import { CardFooter } from './card-footer'

export type CardKind = 'default' | 'page' | 'grouped' | 'nested' | 'thread'
import { AuthorProps } from 'client/shared/types/author'
import { CreatorProps } from 'client/shared/types/creator'
import { ShareData } from 'client/shared/types/share'
import { ShareAnalytics } from 'client/shared/blocks/share-button'
import { ContextMenuDataProps } from 'client/shared/blocks/context-menu'
import { Gender } from 'client/shared/types/gender'
import Spacer from 'client/shared/blocks/spacer/spacer'
import { State } from 'shared/types/redux'
import { QueryParams } from 'shared/tools/url-helper'
import { compose } from 'lodash/fp'

export type CardHeaderProps = {
  user: CreatorProps | AuthorProps
  userPath?: string
  actionMessage?: React.ReactNode | string
  actionValues?: { gender?: Gender }
  path: string
  item?: {
    title: string
    url: string
  }
  date: number
  isLoggedIn?: boolean
  showAuthPopup?: () => void
  kind?: CardKind
}

type Props = {
  header: CardHeaderProps
  extraHeader?: React.ReactNode
  footer?: {
    likeCount?: number
    addToShelfLink?: string
    commentCount?: number
    shareCount?: number
    shareData?: ShareData
    analytics?: ShareAnalytics
    menuData?: ContextMenuDataProps
    liked: boolean
  }
  as?: string
  onCommentClick?: () => void
  onReplyClick?: () => void
  onLikeClick?: () => void
  kind: CardKind
  anchor?: string
  active?: boolean
  children: React.ReactNode
  query?: QueryParams
}

const connectWrapper = connect((state: State) => ({
  query: state.app.storedQuery,
}))

class _Card extends Component<Props> {
  static defaultProps = {
    kind: 'default',
    active: false,
  }

  render(): JSX.Element {
    const {
      header: _header,
      extraHeader,
      footer,
      children,
      onCommentClick: _onCommentClick,
      onReplyClick,
      onLikeClick,
      active,
      anchor,
      kind,
      query,
      as = 'div',
    } = this.props

    const header = { ..._header, kind, query }
    const onCommentClick = _onCommentClick || onReplyClick

    const baseClassName = 'card'
    const kindModifier = `${baseClassName}_${kind}`
    const activeModifier = active ? `${baseClassName}_active` : ''
    const extraHeaderModifier = extraHeader
      ? `${baseClassName}_with-extra-header`
      : ''
    const fullClassName = `${baseClassName} ${kindModifier} ${activeModifier} ${extraHeaderModifier}`

    const anchorProps = anchor ? { id: anchor } : {}

    const CardBody = createElement(as, {
      children: (
        <>
          {extraHeader && <CardExtraHeader>{extraHeader}</CardExtraHeader>}
          {_header && <CardHeader {...header} />}
          <Spacer />
          <div className="card__body">{children}</div>
          {footer && (
            <CardFooter
              {...footer}
              kind={kind}
              onCommentClick={onCommentClick}
              onLikeClick={onLikeClick}
            />
          )}
        </>
      ),
      className: fullClassName,
      anchorProps,
    })
    return CardBody
  }
}

const wrappers = compose(connectWrapper)
export const Card = wrappers(_Card)
