/*
 * This is a reimplementation (mostly through shameless copypasting, with some adaptation)
 * of react-intl's Relative component
 * (see https://github.com/yahoo/react-intl/blob/master/src/components/relative.js
 * for the original)
 */

import { Component } from 'react'
import RelativeFormat from 'intl-relativeformat'
import { connect } from 'react-redux'
import { State as ConnectedState } from 'shared/types/redux'

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

// The maximum timer delay value is a 32-bit signed integer.
// See: https://mdn.io/setTimeout
const MAX_TIMER_DELAY = 2147483647

function selectUnits(delta) {
  const absDelta = Math.abs(delta)

  if (absDelta < MINUTE) {
    return 'second'
  }

  if (absDelta < HOUR) {
    return 'minute'
  }

  if (absDelta < DAY) {
    return 'hour'
  }

  // The maximum scheduled delay will be measured in days since the maximum
  // timer delay is less than the number of milliseconds in 25 days.
  return 'day'
}

function getUnitDelay(units) {
  switch (units) {
    case 'second':
      return SECOND
    case 'minute':
      return MINUTE
    case 'hour':
      return HOUR
    case 'day':
      return DAY
    default:
      return MAX_TIMER_DELAY
  }
}

function isSameDate(a, b) {
  if (a === b) {
    return true
  }

  const aTime = new Date(a).getTime()
  const bTime = new Date(b).getTime()

  return isFinite(aTime) && isFinite(bTime) && aTime === bTime
}

type Props = {
  value: number // timestamp in milliseconds
  format?: string
  updateInterval?: number
  initialNow?: number
  language: string // from connected redux store
}

type State = {
  now: number // timestamp, in milliseconds
}

class RelativeDate extends Component<Props, State> {
  _timer: TimeoutID | null | undefined = null

  static defaultProps = {
    updateInterval: 1000 * 10,
  }

  constructor(props: Props) {
    super(props)
    const now = props.initialNow || Date.now()

    // `now` is stored as state so that `render()` remains a function of
    // props + state, instead of accessing `Date.now()` inside `render()`.
    this.state = { now }
  }

  scheduleNextUpdate(props: Props, state: State) {
    // Cancel the pending update because we're scheduling a new update.
    if (this._timer) {
      clearTimeout(this._timer)
    }

    const { value, updateInterval } = props
    const time = new Date(value).getTime()

    // If the `updateInterval` is falsy, including `0` or we don't have a
    // valid date, then auto updates have been turned off, so we bail and
    // skip scheduling an update.
    if (!updateInterval) {
      return
    }

    const delta = time - state.now
    const unitDelay = getUnitDelay(selectUnits(delta))
    const unitRemainder = Math.abs(delta % unitDelay)

    // We want the largest possible timer delay which will still display
    // accurate information while reducing unnecessary re-renders. The delay
    // should be until the next "interesting" moment, like a tick from
    // "1 minute ago" to "2 minutes ago" when the delta is 120,000ms.
    const delay =
      delta < 0
        ? Math.max(updateInterval, unitDelay - unitRemainder)
        : Math.max(updateInterval, unitRemainder)

    this._timer = setTimeout(() => {
      this.setState({ now: Date.now() })
    }, delay)
  }

  componentDidMount() {
    this.scheduleNextUpdate(this.props, this.state)
  }

  componentDidUpdate(prevProps: Props) {
    if (!isSameDate(prevProps.value, this.props.value)) {
      // When the `props.value` date changes, `state.now` needs to be updated,
      // and the next update can be rescheduled.
      this.setState({ now: Date.now() }, () => {
        this.scheduleNextUpdate(this.props, this.state)
      })
    } else {
      // component updated due to an update of its state;
      // the next update must be scheduled
      this.scheduleNextUpdate(this.props, this.state)
    }
  }

  componentWillUnmount() {
    if (this._timer) {
      clearTimeout(this._timer)
    }
  }

  render() {
    const { value, language } = this.props
    const relativeFormat = new RelativeFormat(language)
    const formattedRelative = relativeFormat.format(value)

    return formattedRelative
  }
}

const connectWrapper = connect((state: ConnectedState) => ({
  language: state.currentUser.data.locale,
}))

export default connectWrapper(RelativeDate)
