export function getAttribute(
  selector: string,
  attr: string,
): string | null | undefined {
  const elem = document.querySelector(selector)
  if (elem) return elem.getAttribute(attr)
}

export function focusCommentForm(): void {
  setTimeout(() => {
    const commentFormEl = document.getElementById('CommentForm_textarea')
    if (commentFormEl) commentFormEl.focus()
  }, 400)
}
