import React, { Component } from 'react'
import merge from 'lodash/merge'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import { Dropdown } from 'client/shared/blocks/dropdown'
import SVGInline from 'react-svg-inline'

import ClipboardButton from 'react-clipboard.js'
import { encodeParams } from 'client/shared/helpers/url-helpers'
import { addParamsToPath, getUrlWithUtm } from 'shared/tools/url-helper'
import { getAttribute } from 'client/shared/helpers/dom-helpers'

import TwitterIcon from 'client/shared/icons/twitter.svg'
import FacebookIcon from 'client/shared/icons/facebook.svg'
import LinkIcon from 'client/shared/icons/share_link.svg'

import { ShareDataForSingleSocialNetwork } from 'client/shared/types/share'
import { isEmpty } from 'lodash/fp'
import { Trans } from '@lingui/react'
import { SHARE_UTMS } from 'client/shared/helpers/share-helper'

import './share.styl'
import { showAlert } from 'client/shared/reducers/alert-reducer'

const WINDOW_WIDTH = 926
const WINDOW_HEIGHT = 436

type ShareParams = {
  url?: string
  title?: string
  description?: string
  image?: string
  twitter: ShareDataForSingleSocialNetwork
  facebook: ShareDataForSingleSocialNetwork
  utm?: Record<string, string>
  username?: string
  position?: string
}

type Props = ShareParams & {
  children: React.ReactNode
  analytics: {
    // this prop might need better typing (unless we don't care what's in this object)
    source?: string // this field is added in the render function
  }
} & DecoratorProps

type State = {
  dropdownShown: boolean
}

class Share extends Component<Props, State> {
  static defaultProps = {
    twitter: {},
    facebook: {},
  }

  state = {
    dropdownShown: false,
  }

  handleCopyToClipboardSuccess = (): void => {
    this.props.dispatch(
      showAlert('success', { message: 'alerts.link_copy_success' }),
    )
  }

  handleCopyToClipboardError = (): void => {
    this.props.dispatch(
      showAlert('error', { message: 'alerts.link_copy_error' }),
    )
  }

  render() {
    const { children, position } = this.props

    return (
      <div onClick={this.toggleDropdown} className="share">
        {children}
        <Dropdown
          position={position}
          items={[
            {
              icon: FacebookIcon,
              name: 'facebook',
              title: 'Facebook',
              action: this.share('facebook'),
              analytics: { ...this.props.analytics, source: 'Facebook' },
            },
            {
              icon: TwitterIcon,
              name: 'twitter',
              title: 'Twitter',
              action: this.share('twitter'),
              analytics: { ...this.props.analytics, source: 'Twitter' },
            },
            {
              asChild: true,
              body: (
                <ClipboardButton
                  className="dropdown__item"
                  option-text={this.copyLink}
                  onSuccess={this.handleCopyToClipboardSuccess}
                  onError={this.handleCopyToClipboardError}
                  component="div"
                >
                  <SVGInline
                    svg={LinkIcon}
                    className="dropdown__item-icon dropdown__item-icon-link"
                  />
                  <span className="dropdown__item-title">
                    <Trans id="buttons.copy_link" />
                  </span>
                </ClipboardButton>
              ),
            },
          ]}
          optionText={this.copyLink()}
          onClose={this.hideDropdown}
          hidden={!this.state.dropdownShown}
          onSuccess={this.handleCopyToClipboardSuccess}
        />
      </div>
    )
  }

  copyLink = (): string => {
    const { url, facebook } = this.props
    return addParamsToPath(url || (facebook.url as string), SHARE_UTMS)
  }

  share = (provider: string) => () => {
    const { utm, url } = this.props
    const defaultParams = this.getDefaultParams()
    const shareParams = merge({}, this.props)
    let shareUrl = ''

    if (!isEmpty(utm) && url) {
      switch (provider) {
        case 'twitter':
          shareUrl = addParamsToPath('https://twitter.com/share', {
            ...utm,
            url,
            text: encodeParams({
              title: getAttribute('meta[property="og:title"]', 'content'),
            }),
            utm_source: provider,
          })
          break
        case 'facebook':
          shareUrl = addParamsToPath('https://facebook.com/sharer.php', {
            ...utm,
            u: url,
            utm_source: provider,
          })
          break
      }
    } else {
      shareUrl = this.getShareUrl(
        provider,
        merge(defaultParams, shareParams),
      ) as string
    }

    setTimeout(this.hideDropdown, 0)
    this.openShareWindow(shareUrl)
  }

  getShareUrl(provider: string, params: ShareParams) {
    const { url, title, twitter, facebook, username } = params

    switch (provider) {
      case 'twitter':
        return `https://twitter.com/share?${encodeParams({
          url: getUrlWithUtm(
            twitter.url || (url as string),
            provider,
            username as string,
          ),
          text: twitter.title || (title as string),
        })}`
      case 'facebook':
        return `https://facebook.com/sharer.php?${encodeParams({
          u: getUrlWithUtm(
            facebook.url || (url as string),
            provider,
            username as string,
          ),
        })}`
    }
  }

  getDefaultParams() {
    return {
      url: window.location.href,
      title: getAttribute('meta[property="og:title"]', 'content'),
      description: getAttribute('meta[property="og:description"]', 'content'),
      image: getAttribute('meta[property="og:image"]', 'content'),
      twitter: {
        title: getAttribute('meta[property="twitter:title"]', 'content'),
        description: getAttribute(
          'meta[property="twitter:description"]',
          'content',
        ),
        image: getAttribute('meta[property="twitter:image"]', 'content'),
      },
      facebook: {},
    }
  }

  openShareWindow(url: string | null | undefined) {
    if (!url) return // this shouldn't happen

    const left = window.innerWidth / 2 - WINDOW_WIDTH / 2 + window.screenX
    const top = window.innerHeight / 2 - WINDOW_HEIGHT / 2 + window.screenY

    window.open(
      url,
      '',
      `toolbar=0,status=0,width=${WINDOW_WIDTH},height=${WINDOW_HEIGHT},top=${top},left=${left}`,
    )
  }

  toggleDropdown = () => {
    this.setState({ dropdownShown: !this.state.dropdownShown })
  }

  hideDropdown = () => {
    this.setState({
      dropdownShown: false,
    })
  }
}

export default uiBox(Share)
