import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import SVGInline from 'react-svg-inline'
import { withI18n, withI18nProps } from '@lingui/react'
import Share from 'client/shared/blocks/share'
import Button from 'client/shared/blocks/button'
import ShareIcon from 'client/shared/icons/share.svg'
import { ShareData } from 'client/shared/types/share'
import './share-button.styl'
import { ComponentConstructor } from '@lingui/react/withI18n'

export type ShareAnalytics = {
  object_type: string
  object_id: string
}

type Props = {
  shareData: ShareData
  analytics?: ShareAnalytics
  kind: 'small' | 'medium' | 'big'
  position?: string
} & withI18nProps

class _ShareButton extends Component<Props> {
  static defaultProps = {
    kind: 'small',
  }

  render(): JSX.Element {
    const { kind, i18n } = this.props

    const className = `share-button share-button_${kind}`

    if (['big', 'medium'].includes(kind)) {
      return (
        <Button
          kind={kind === 'medium' ? 'inline' : 'secondary'}
          type="transparent"
          pseudo
          className={className}
          aria-label={i18n._('shared.share')}
        >
          {this.renderShare()}
        </Button>
      )
    } else {
      return <div className={className}>{this.renderShare()}</div>
    }
  }

  renderShare(): JSX.Element {
    const { shareData, position } = this.props

    return (
      <Share
        {...shareData}
        position={position}
        analytics={{ ...this.props.analytics }}
      >
        <div className="share-button__container">
          <SVGInline svg={ShareIcon} className="share-button__icon" />
          <span className="share-button__text">
            <Trans id="shared.share" />
          </span>
        </div>
      </Share>
    )
  }
}

export const ShareButton = withI18n({ update: true })(
  _ShareButton as ComponentConstructor<Props>,
)
