import { Component, createElement } from 'react'
import sanitizeHtml from 'shared/tools/sanitize-html'

import './simple-format.styl'

import {
  normalizeLineBreaks,
  hasOnlyEmoji,
  MATCH_NOT_FORMATED_URL_REGEXP,
  MATCH_EMAILS_REGEX,
  MATCH_FORMATED_URL_REGEXP,
} from 'client/shared/helpers/text-helpers'

type Props = {
  text: string
  element: string
  withLinks: boolean
  classModifiers: string[]
}

function format(text: string, withLinks: boolean) {
  text = sanitizeHtml(text, {
    allowedTags: ['b', 'i', 'em', 'p', 'sup', 'sub', 'br'],
  })

  if (withLinks) {
    text = text
      .toString()
      .replace(
        MATCH_FORMATED_URL_REGEXP,
        `<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>`,
      )
      .replace(
        MATCH_NOT_FORMATED_URL_REGEXP,
        (match: string) =>
          ` <a href="${match.trim()}" target="_blank" rel="noopener noreferrer">${match.trim()}</a>`,
      )
      .replace(
        MATCH_EMAILS_REGEX,
        `<a href="mailto:$&" rel="noopener noreferrer">$&</a>`,
      )
  }

  return normalizeLineBreaks(text.trim())
}

class SimpleFormat extends Component<Props> {
  static defaultProps = {
    text: '',
    element: 'div',
    withLinks: false,
    classModifiers: [],
  }

  getClasses() {
    const { text } = this.props
    let { classModifiers } = this.props

    if (hasOnlyEmoji(text)) {
      classModifiers = [...classModifiers, 'only-emoji']
    }

    return classModifiers.map(modifier => `simple-format_${modifier}`).join(' ')
  }

  render() {
    const { text, element, withLinks } = this.props

    return createElement(element, {
      dangerouslySetInnerHTML: { __html: format(text, withLinks) },
      className: this.getClasses(),
    })
  }
}

export default SimpleFormat
