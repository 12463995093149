// as suggested here:
// https://github.com/facebook/react/pull/11533#issuecomment-343738838

import React from 'react'

function NoIndex(props) {
  return (
    <span>
      <span dangerouslySetInnerHTML={{ __html: '<!--noindex-->' }} />
      {props.children}
      <span dangerouslySetInnerHTML={{ __html: '<!--/noindex-->' }} />
    </span>
  )
}

export default NoIndex
