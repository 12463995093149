import { getAttribute } from 'client/shared/helpers/dom-helpers'
import { encodeParams } from 'client/shared/helpers/url-helpers'
import { addParamsToPath } from 'shared/tools/url-helper'

const WINDOW_WIDTH = 926
const WINDOW_HEIGHT = 436

export default function share(provider, params = {}) {
  const defaultParams = getDefaultShareParams(params)
  const shareParams = Object.assign(defaultParams, params)
  openShareWindow(getShareUrl(provider, shareParams))
}

export const SHARE_UTMS = {
  utm_source: 'other_socials',
  utm_campaign: 'usersreferral',
  utm_content: 'web',
  utm_medium: 'referral',
}

function getShareUrl(provider, params) {
  const { url, title, image, twitter, facebook } = params

  switch (provider) {
    case 'twitter':
      return `https://twitter.com/share?${encodeParams({
        url: twitter.url || url,
        text: twitter.title || title,
        image: twitter.image !== undefined ? twitter.image : image,
      })}`
    case 'facebook':
      return `https://facebook.com/sharer.php?${encodeParams({
        u: facebook.url || url,
      })}`
    case 'link':
      return addParamsToPath(url, SHARE_UTMS)
    default:
      break
  }
}

function getDefaultShareParams(options) {
  const { useCurrentPageImage = true } = options

  return {
    url: window.location.href,
    title: getAttribute('meta[property="og:title"]', 'content'),
    description: getAttribute('meta[property="og:description"]', 'content'),
    image: useCurrentPageImage
      ? getAttribute('meta[property="og:image"]', 'content')
      : undefined,
    twitter: {
      title: getAttribute('meta[property="twitter:title"]', 'content'),
      description: getAttribute(
        'meta[property="twitter:description"]',
        'content',
      ),
      image: getAttribute('meta[property="twitter:image"]', 'content'),
    },
    facebook: {},
  }
}

function openShareWindow(url) {
  const left = window.innerWidth / 2 - WINDOW_WIDTH / 2 + window.screenX
  const top = window.innerHeight / 2 - WINDOW_HEIGHT / 2 + window.screenY

  window.open(
    url,
    '',
    `toolbar=0,status=0,width=${WINDOW_WIDTH},height=${WINDOW_HEIGHT},top=${top},left=${left}`,
  )
}
